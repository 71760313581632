import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faCircle, faCoins, faTimes, faBars } from "@fortawesome/free-solid-svg-icons";

class MobileMenu extends React.Component {

	constructor(props) {

		super(props);
		this.state = {isOpen: false }
		this.handleMenuButton = this.handleMenuButton.bind(this);

	}

	//	Update menu state
	handleMenuButton(event) {
		this.setState({ isOpen: !this.state.isOpen });
	}


	renderMobileMenuOpened = () => {

		return (
			<div>
				<div class="space_fixer"></div>
				<div className="menu_wrapper">
					<nav id="menu">
						<div className="menu">
							<div id="closeButton" onClick={this.handleMenuButton}>
								<FontAwesomeIcon icon={faTimes} />
							</div>
							<div className="menu_user">
								<div className="menu_id">
									<div className="profile_id">
										<FontAwesomeIcon icon={faUser} />
										<div className="id">Guest</div>
										<div className="balance">Balance: 0 tokens</div>
									</div>
									<a href="/buy-tokens/" target="_blank" className="tokens">TOKENS <FontAwesomeIcon icon={faCoins} /></a>
								</div>
							</div>
							<div className="menu_title"><FontAwesomeIcon icon={faCircle} /> Who's online now ?</div>
							<div className="menu_links">
								<NavLink to="/" activeClassName="active" onClick={this.handleMenuButton} exact>Home</NavLink>
								<NavLink to="/category/teen/" activeClassName="active" onClick={this.handleMenuButton}>Teen</NavLink>
								<NavLink to="/category/milf/" activeClassName="active" onClick={this.handleMenuButton}>MILF</NavLink>
								<NavLink to="/category/mature/" activeClassName="active" onClick={this.handleMenuButton}>Mature</NavLink>
								<NavLink to="/category/fetish/" activeClassName="active" onClick={this.handleMenuButton}>Fetish</NavLink>
								<NavLink to="/category/camsebony/" activeClassName="active" onClick={this.handleMenuButton}>CamsEbony</NavLink>
								<NavLink to="/category/ebonygirlcams/" activeClassName="active" onClick={this.handleMenuButton}>EbonyGirlCams</NavLink>
								<NavLink to="/category/liveblack/" activeClassName="active" onClick={this.handleMenuButton}>LiveBlack</NavLink>
							</div>
							<div className="menu_footer">
								<p className="copyright">Copyright &copy; 2020 ebonycamly.com</p>
							</div>
						</div>
					</nav>
					<div className="overlay" onClick={this.handleMenuButton}></div>
				</div>
			</div>
		)
	}


	renderMobileMenuClosed = () => {

		return(
			<div id="menuButton" onClick={this.handleMenuButton}>
				<FontAwesomeIcon icon={faBars} />
			</div>
		)

	}

	render() {

		return (
			<div>
				{this.state.isOpen ?  this.renderMobileMenuOpened() : this.renderMobileMenuClosed()}
			</div>
		);
	}
}

export default MobileMenu;