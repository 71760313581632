//
//	Order user part
//
import React, { Component } from 'react';
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";

const mapStateToProps = state  => {
	return {
		users: state.users,
		usersVisible: state.usersVisible,
		usersTotal: state.usersTotal,
		showItems: state.showItems,
		userOrder: state.userOrder,
		top_text: state.top_text,
	};
};

class UserSort extends Component {

	constructor(props) {

		super(props);

	}

	//	Order users by specific..
	orderUsers = (event) => {
		this.props.dispatch({type: 'ORDER_USERS', order:event.target.attributes.getNamedItem('data-sort').value});
	};

	
	render() {

		if(window.innerWidth <= 768 ) {
			return (
				<div className="filters_container">
					<div className="filters">
						<button className="filter" id={this.props.userOrder == 'ATOZ' ? 'active' : ''} data-sort="ATOZ" onClick={this.orderUsers}><FontAwesomeIcon icon={faTag} /> Name A-Z</button>
						<button className="filter" id={this.props.userOrder == 'ZTOA' ? 'active' : ''} data-sort="ZTOA" onClick={this.orderUsers}><FontAwesomeIcon icon={faTag} /> Name Z-A</button>
						<button className="filter" id={this.props.userOrder == 'POPULAR' ? 'active' : ''} data-sort="POPULAR" onClick={this.orderUsers}><FontAwesomeIcon icon={faTag} /> Popular</button>
						<button className="filter" id={this.props.userOrder == 'USERS' ? 'active' : ''} data-sort="USERS"onClick={this.orderUsers}><FontAwesomeIcon icon={faTag} /> Chatters</button>
						<button className="filter" id={this.props.userOrder == 'OLDEST' ? 'active' : ''} data-sort="OLDEST" onClick={this.orderUsers}><FontAwesomeIcon icon={faTag} /> Oldest</button>
						<button className="filter" id={this.props.userOrder == 'YOUNGEST' ? 'active' : ''} data-sort="YOUNGEST" onClick={this.orderUsers}><FontAwesomeIcon icon={faTag} /> Youngest</button>
					</div>
				</div>
			)
		} else {
			
			return (
				<div className="filters_desktop">
					<button className="filter_tag" id={this.props.userOrder == 'ATOZ' ? 'active' : ''} data-sort="ATOZ" onClick={this.orderUsers}><FontAwesomeIcon icon={faTag} /> Name A-Z</button>
					<button className="filter_tag" id={this.props.userOrder == 'ZTOA' ? 'active' : ''} data-sort="ZTOA" onClick={this.orderUsers}><FontAwesomeIcon icon={faTag} /> Name Z-A</button>
					<button className="filter_tag" id={this.props.userOrder == 'POPULAR' ? 'active' : ''} data-sort="POPULAR" onClick={this.orderUsers}><FontAwesomeIcon icon={faTag} /> Popular</button>
					<button className="filter_tag" id={this.props.userOrder == 'USERS' ? 'active' : ''} data-sort="USERS"onClick={this.orderUsers}><FontAwesomeIcon icon={faTag} /> Chatters</button>
					<button className="filter_tag" id={this.props.userOrder == 'OLDEST' ? 'active' : ''} data-sort="OLDEST" onClick={this.orderUsers}><FontAwesomeIcon icon={faTag} /> Oldest</button>
					<button className="filter_tag" id={this.props.userOrder == 'YOUNGEST' ? 'active' : ''} data-sort="YOUNGEST" onClick={this.orderUsers}><FontAwesomeIcon icon={faTag} /> Youngest</button>
				</div>
			)
		}
	}

}

export default connect(mapStateToProps)(UserSort);