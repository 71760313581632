import React, { Component } from 'react';
import { connect } from "react-redux";


//	This maps the data store to local variables.
const mapStateToProps = state  => {
	return {
	  footer_text: state.footer_text
	};
};

//	Main Home Class
class Footer extends Component {

	constructor(props) {

		super(props);
		
	}

	render() {
	  return (
		<div className="footer">
			<div className="footer_content" dangerouslySetInnerHTML={{__html: this.props.footer_text}}></div>
			<div className="footer_sec">&copy; 2020 ebonycamly.com - All Rights Reserved</div>
		</div>
	  );
	}
}

//	Export with redux connections.
export default connect(mapStateToProps)(Footer);




