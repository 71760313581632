import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import 'src/App.css';

//	Pages
import Home from 'src/components/pages/Home';
import Tag from 'src/components/pages/Tag';
import Loading from 'src/components/pages/Loading';
import Search from 'src/components/pages/Search';
import Chatroom from 'src/components/pages/Chatroom';
import Error from 'src/components/pages/Error';

class App extends Component {
  render() {
    return (
	<div className="App">
	  <BrowserRouter>
	      <Switch>
	        <Route path="/" component={Home} exact />
			<Route path="/category/" component={Tag} />
			<Route path="/search/" component={Search} />
			<Route path="/buy-tokens/" component={Loading} />
			<Route path="/stream/" component={Loading} />
			<Route path="/croom/" component={Loading} />
			<Route path="/chat/" component={Chatroom} />
			<Route component={Error} />
	      </Switch>
	  </BrowserRouter>
	</div>
    );
  }
}

export default App;