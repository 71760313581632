import React, { Component } from 'react';
import defaultUser from 'public/static/images/default-profile.svg';
import { NavLink } from 'react-router-dom';
import Img from 'react-image';

class User extends Component {

	constructor(props) {

		super(props);

		//	Set image to default, after image has been completly loaded will render with the loaded image.
		this.state = { image_src: defaultUser };

	}


	handleImageLoaded() {

		this.setState({ image_src: this.props.info.picture_url });
		
	}


	renderEmptyModel = () => {
		return(
			<div key={this.props.info.id}>
				<img src={defaultUser} alt="User Default" />
				<div className="username">Loading ...</div>
			</div>
		)
	}
	

	renderLiveModel = () => {
		return(
			<div key={this.props.info.id}>
				<div>
					<div>
						<NavLink to={this.props.info.link} exact>
							<Img src={document.location.protocol+'//'+document.domain+this.state.image_src} title={this.props.info.username} alt={this.props.info.username} onLoad={this.handleImageLoaded.bind(this) } />
						</NavLink>
					</div>
				</div>
				<div className="username">{this.props.info.username}</div>
			</div>
		)
	}

	render() {

		if( this.props.info.picture === "" ) {

			return(
				this.renderEmptyModel()
			);

		} else {
			
			return (
				this.renderLiveModel()
			);

		}

	}
	
}

export default User;