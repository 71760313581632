import React from 'react';
import {Helmet} from 'react-helmet';

const Loading = () => {
    return (
		<div>
			<Helmet>
				<title>Loading....</title>
				<meta name="robots" content="noindex" />
				<meta name="googlebot" content="noindex" />
			</Helmet>
		</div>
    );
}

export default Loading;