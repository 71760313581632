//
//	Handle rendering of header, basicly for switches between mobile and desktop
//
import React from 'react';
import logo from 'public/static/images/logo.png';
import play from 'public/static/images/play.png';
import Search from 'src/components/parts/SearchBox';
import MobileMenu from 'src/components/parts/MobileMenu';
import UserSort from 'src/components/parts/UserSort';
import Img from 'react-image';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes, faSlidersH, faBars, faVideo } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from 'react-router-dom';
import { connect } from "react-redux";

//	This maps the data store to local variables.
const mapStateToProps = state  => {
	return {
		top_text: state.top_text,
		usersTotal: state.usersTotal,
	};
};

class Header extends React.Component {

	constructor(props) {

		super(props);
		this.state = {isOpen: false, filtersOpened: false }
		this.handleSearchBox = this.handleSearchBox.bind(this);
		this.handleFilters = this.handleFilters.bind(this);
	}
	
	// Handle search box visibility
	handleSearchBox(event) {
		this.setState({ isOpen: !this.state.isOpen });
		this.setState({ filtersOpened: false });
	}
	// Handle filters visibility
	handleFilters(event) {
		this.setState({ filtersOpened: !this.state.filtersOpened });
	}

	renderMobileHeader = () => {
		return(
			<div className="header_mobile">
				<div className="header_mobile_top">
					<MobileMenu />
					<div className="logo">
						<a href="/"><Img src={logo} className="logo_img" alt="EbonyCamly Logo" /></a>
					</div>
					<div className="search_btn" onClick={this.handleSearchBox}>
						{ this.state.isOpen ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faSearch} color="#cecece" /> }
					</div>
				</div>
				<div className="header_mobile_bottom">
					<Img src={play} className="play" alt="Play Thumb" />
					<h1 className="header_title">{this.props.top_text}</h1>
					<div className="sort_btn" onClick={this.handleFilters}><FontAwesomeIcon icon={faSlidersH} /></div>
					{ this.state.isOpen ? <Search /> : null }
					{ this.state.filtersOpened ? <UserSort /> : null }
				</div>
			</div>
		)
	}

	renderDesktopHeader = () => {
		return(
		  <div className="header_desktop">
			<div className="part_top">
				<div className="part_logo">
					<div><a href="/"><Img src={logo} className="logo_img" alt="EbonyCamly Logo" title="Home Page" /></a></div>
					<div className="models_online"><span>{this.props.usersTotal}</span>models online </div>
				</div>
				<div className="part_menu">
					<ul>
						<li><NavLink to="/" activeClassName="active_link" exact>HOME</NavLink></li>
						<li><NavLink to="/category/teen/" activeClassName="active_link">TEEN</NavLink></li>
						<li><NavLink to="/category/milf/" activeClassName="active_link">MILF</NavLink></li>
						<li><NavLink to="/category/mature/" activeClassName="active_link">MATURE</NavLink></li>
						<li><NavLink to="/category/fetish/" activeClassName="active_link">FETISH</NavLink></li>
					</ul>
				</div>
			</div>
			<div className="part_bottom">
				<div className="part_bottom_left">
					<h1>MOCHA GIRL NETWORKS:</h1>
					<div className="networks">
						<NavLink to="/category/camsebony/" activeClassName="active_link">CamsEbony</NavLink>
						<NavLink to="/category/ebonygirlcams/" activeClassName="active_link">EbonyGirlCams</NavLink>
						<NavLink to="/category/liveblack/" activeClassName="active_link">LiveBlack</NavLink>
					</div>
				</div>
				<div className="part_bottom_right">
					<div className="part_bottom_search">
						<Search />
					</div>
				</div>
			</div>
			<div className="part_mixed">
				<div className="part_title"><h1>{this.props.top_text}</h1></div>
				<div className="part_filters"><UserSort /></div>
			</div>
		  </div>
		)
	}

	
	render() {

		// At width 768 or greater we will display the desktop header ( default should always be for mobile )
		if(window.innerWidth > 768 ) {

			return(
				this.renderDesktopHeader()
			);

		} else {

			return (
				this.renderMobileHeader()
			);

		}
	}
}

//	Export with redux connections.
export default connect(mapStateToProps)(Header);